<template>
    <div class="main">
        <div class="header-container">
            <list-header
                    v-for="(list, index) in lists"
                    :data="list"
                    :template="listHeaderTemplate"
                    :style="`min-width: ${listWidth}px`"
                    :component-style="listHeaderStyle"
                    :key="`header_${index}`"
            ></list-header>
        </div>
        <el-scrollbar :style="{height:'100%'}" wrap-style="overflow-x:hidden;">
            <div class="tasks-container">
                <el-collapse class="group-list" accordion v-if="groups.length > 0">
                    <el-collapse-item v-for="(group, index) in groups" :key="index">
                        <template slot="title">
                            <group-title :data="group"></group-title>
                        </template>
                        <el-collapse class="group-list" accordion v-if="(group.children || []).length > 0">
                            <el-collapse-item v-for="(child, childIndex) in group.children" :key="childIndex">
                                <template slot="title">
                                    <group-title :data="child"></group-title>
                                </template>
                                <tasks-container
                                        :lists="lists"
                                        :template="taskTemplate"
                                        :task-style="taskStyle"
                                        :list-width="listWidth"
                                        :tasks="child.tasks"
                                ></tasks-container>
                            </el-collapse-item>
                        </el-collapse>
                        <tasks-container
                                v-else
                                :lists="lists"
                                :template="taskTemplate"
                                :task-style="taskStyle"
                                :list-width="listWidth"
                                :tasks="group.tasks"
                        ></tasks-container>
                    </el-collapse-item>
                </el-collapse>
                <tasks-container
                        v-else
                        :lists="lists"
                        :template="taskTemplate"
                        :task-style="taskStyle"
                        :list-width="listWidth"
                        :tasks="tasks"
                ></tasks-container>
            </div>
        </el-scrollbar>
        <div class="footer-container">
            <list-footer
                    v-for="(list, index) in lists"
                    :data="list"
                    :template="listFooterTemplate"
                    :style="`min-width: ${listWidth}px`"
                    :component-style="listFooterStyle"
                    :key="`header_${index}`"></list-footer>
        </div>
    </div>
</template>

<script>
import ListHeader from './components/ListHeader'
import ListFooter from './components/ListFooter'
import GroupTitle from './components/GroupTitle'
import TasksContainer from './components/TasksContainer'

import { statuses, groups, tasks } from './data/data'
import Vue from 'vue'

export default {
  name: 'draggable-list',
  props: {
    listHeaderTemplate: {
      type: Array,
      default: () => {
        return []
      }
    },
    listHeaderStyle: {
      type: String,
      default: ''
    },
    listFooterTemplate: {
      type: Array,
      default: () => {
        return []
      }
    },
    listFooterStyle: {
      type: String,
      default: ''
    },
    taskTemplate: {
      type: Array,
      default: () => {
        return []
      }
    },
    taskStyle: {
      type: String,
      default: ''
    },
    listWidth: {
      type: Number,
      default: 300
    },
    tasks: {
      type: Array,
      default: () => []
    },
    lists: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ListHeader,
    ListFooter,
    GroupTitle,
    TasksContainer
  },
  provide () {
    return {
      getEventBus: this.getEventBus
    }
  },
  data () {
    return {
      eventBus: new Vue()
    }
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    onChange (event) {
      this.$emit('change', event.element, event.list)
    }
  },
  mounted () {
    this.eventBus.$on('change', this.onChange)
  },
  beforeDestroy () {
    this.eventBus.$off('change', this.onChange)
  }
}
</script>

<style scoped>
    .main {
        height: 100%;
        float: left;
        display: flex;
        flex-direction: column;
    }

    .main .footer-container, .main .header-container {
        padding: 10px;
        display: flex;
        flex-direction: row;
    }

    .main .tasks-container .group-list /deep/ .el-collapse-item.is-active {
        position: -webkit-sticky;
        position: sticky;
    }

    .main .header-container /deep/ div {
        margin-right: 10px;
    }

    .main .footer-container /deep/ div {
        margin-right: 10px;
    }

    .main .tasks-container {
        flex: 1 1 auto;
        margin-left: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
    }

    .main .tasks-container .group-list /deep/ .el-collapse-item /deep/ .el-collapse-item .el-collapse-item__header {
        margin-left: 20px;
    }

    .main .tasks-container .group-list {
        width: 100%;
        border: none;
    }

    .main .tasks-container .group-list /deep/ .el-collapse-item__header {
        direction: rtl;
        justify-content: flex-end;
    }

    .main .tasks-container .group-list /deep/ .el-collapse-item__arrow {
        margin-left: 5px;
        color: #89959F;
        font-weight: bold;
        font-size: 15px;
    }

    .main .tasks-container .group-list /deep/ .el-collapse-item__header, .main .tasks-container .group-list /deep/ .el-collapse-item__wrap {
        border: none;
    }

    .main .tasks-container .group-list /deep/ .el-collapse-item__content {
        padding-bottom: 0px;
    }
</style>
